import { createQueryKeys } from '@lukemorales/query-key-factory';

import { downloadFile } from '@/services/file-upload';

export const blob = createQueryKeys('blob', {
  // @ts-expect-error, ref: https://github.com/lukemorales/query-key-factory/issues/83
  hash: (hashFileName: string) => ({
    queryKey: [hashFileName],
    queryFn: () => downloadFile(hashFileName),
    staleTime: 1000 * 60 * 5,
  }),
});
