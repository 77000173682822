// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from 'C:/code/temp/20240914174329/da-vinci-frontend/src/models/project360';
import model_2 from 'C:/code/temp/20240914174329/da-vinci-frontend/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'project360', model: model_1 },
model_2: { namespace: '@@initialState', model: model_2 },
} as const
