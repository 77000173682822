import { FILE_UPLOAD_ENDPOINT } from '@/constants/api';
import { FileSubContainer, TemplateCodeType } from '@/enums/fileEnum';
import request from '@/utils/request';

import { FileObject } from './file.type';
import { APIRes } from './types';

interface UploadFileReq {
  containerName: string;
  subContainerName: FileSubContainer;
  file: any; // todo: fix any
}

export const uploadFile = (
  data: UploadFileReq,
): Promise<APIRes<FileObject>> => {
  return request.postForm(FILE_UPLOAD_ENDPOINT, data);
};
/**下载文件 Blob */
export const downloadFile = (hashFileName: string): Promise<any> => {
  return request(`${FILE_UPLOAD_ENDPOINT}/${hashFileName}`, {
    method: 'GET',
    params: {},
    responseType: 'blob',
  });
};

interface TemplateParams {
  id?: number;
  code: TemplateCodeType;
}
/**上传 模板 */
export async function uploadTemplate(params: TemplateParams): Promise<any> {
  return request('/api/v1/files/template', {
    method: 'POST',
    params,
  });
}
/**下载 模板 */
export async function downloadTemplate(params: TemplateParams): Promise<any> {
  return request(`/api/v1/files/template/${params.code}`, {
    method: 'GET',
    params,
    responseType: 'blob',
  });
}
