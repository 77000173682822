export enum CommonPermissions {
  authenticated = 'MENU@@authenticated',
  statistics = 'MENU@@statistics',
}

export enum FrontPermissionEnum {
  projectOverview = 'MENU@@projectOverview',
  serviceCatalog = 'MENU@@serviceCatalog',
  collaboration = 'MENU@@collaboration',
  dataService = 'MENU@@dataService',
  project360 = 'MENU@@project360',
  targetSequenceDetail = 'MENU@@targetSequenceDetail',
  // proteinProduction = 'MENU@@proteinProduction',
  // manageExportInfo = 'MENU@@manageExportInfo',

  /**Observation Client */
  projectOverviewObservation = 'MENU@@projectOverviewObservation',
  dataServiceObservation = 'MENU@@dataServiceObservation',
}

export enum BackPermissionEnum {
  homePage = 'MENU@@homePage',
  myClients = 'MENU@@myClients',
  myProposals = 'MENU@@myProposals',
  myContracts = 'MENU@@myContracts',
  myProjects = 'MENU@@myProjects',
  projectMemo = 'MENU@@projectMemo',
  accessManagement = 'MENU@@accessManagement',
  myCos = 'MENU@@myCos',
  /**SM */
  myShipments = 'MENU@@myShipments',
  targetSequence = 'MENU@@targetSequence',
}

export const permissionAllCode = [
  ...Object.values(CommonPermissions),
  ...Object.values(FrontPermissionEnum),
  ...Object.values(BackPermissionEnum),
];
