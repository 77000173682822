import './index.less';

import {
  CloseOutlined,
  ColumnWidthOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { history, useAliveController, useLocation } from '@umijs/max';
import { Dropdown, Tabs } from 'antd';
import { useState } from 'react';

import { getLocale } from '@/hooks/useLocale';

export default (): React.ReactElement => {
  const { pathname } = useLocation();

  // 获取缓存列表
  const { getCachingNodes, dropScope, clear, refreshScope } =
    useAliveController();
  const cachingNodes = getCachingNodes();
  const [open, setOpen] = useState<{ path: string; open: boolean }>({
    path: '',
    open: false,
  });

  // 阻止右键事件冒泡
  const onRightClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => open.open && open.path === name && e.stopPropagation();

  const handlePush = (path: string) => {
    let obj: any = cachingNodes.find((v) => v.name === path);
    const { pathname, search, state } = obj.location;
    history.push(
      {
        pathname,
        search,
      },
      state,
    );
  };

  // 点击tab，跳转页面
  const clickTab = (path: string) => {
    handlePush(path);
    // refreshScope(path);
  };

  // 关闭tab，销毁缓存
  const editTab = (path: any) => {
    dropScope(path);
    // 关闭当前页面，需跳转到其他页签
    if (path === pathname) {
      const index = cachingNodes.findIndex((item) => item.name === path);
      if (index > 0) {
        handlePush(cachingNodes[index - 1].name as string);
      } else {
        handlePush(cachingNodes[1].name as string);
      }
    }
  };

  // 关闭其他
  const onOther = (e: any) => {
    const targetKey = JSON.parse(e?.key).name;
    handlePush(targetKey);
    clear();
  };

  // 重新加载
  const onRefresh = (e: any) => {
    const targetKey = JSON.parse(e?.key).name;
    refreshScope(targetKey);
  };

  const closeCurrent = (e: any, name: string) => {
    e.stopPropagation();
    editTab(name);
  };
  const labelDropdown = (name: string, label: string) => {
    // const lastIndex = cachingNodes.findIndex((item) => item.name === name);
    return (
      <div onClick={(e) => onRightClick(e, name)}>
        <Dropdown
          trigger={['contextMenu']}
          onOpenChange={(e) => setOpen({ path: name, open: e })}
          menu={{
            items: [
              {
                label: (
                  <span style={{ display: 'flex' }}>
                    <ReloadOutlined />
                    &nbsp; {getLocale('tab.refresh')}
                  </span>
                ),
                key: JSON.stringify({ name, key: 'refresh' }),
                onClick: onRefresh,
              },
              {
                label: (
                  <span style={{ display: 'flex' }}>
                    <ColumnWidthOutlined />
                    &nbsp; {getLocale('tab.closeOthers')}
                  </span>
                ),
                key: JSON.stringify({ name, key: 'other' }),
                disabled: cachingNodes.length <= 1,
                onClick: onOther,
              },
            ],
          }}
        >
          <div className={cachingNodes.length > 1 ? 'dropdown-label' : ''}>
            {label}
            {cachingNodes.length > 1 && (
              <CloseOutlined
                style={{ fontSize: '10px', marginLeft: '12px' }}
                onClick={(e) => closeCurrent(e, name)}
              />
            )}
          </div>
        </Dropdown>
      </div>
    );
  };

  const tabItems = cachingNodes.map((item: any) => ({
    label: labelDropdown(item.name, item.tabName),
    key: item.name,
    closable: cachingNodes.length > 1,
  }));

  return (
    <Tabs
      hideAdd
      size="middle"
      className="base-tabs bg-white"
      activeKey={pathname}
      onTabClick={clickTab}
      items={tabItems}
    />
  );
};
