import { history } from '@umijs/max';
import { Button, Result } from 'antd';
import { FC } from 'react';

import { useProfile } from '@/hooks/useProfile';
import { useSiteMaintenance } from '@/hooks/useSiteMaintenance';
import { getHomepageUrl } from '@/utils/permission';

const NoPermissionPage: FC = () => {
  const initialState = useProfile();
  const handleClick = () => {
    history.replace(getHomepageUrl(initialState?.roleList));
  };
  useSiteMaintenance();
  return (
    <Result
      status="403"
      title="No permission"
      subTitle="Sorry, you don't have permission to this page, please contact system admin."
      extra={
        <Button type="primary" onClick={handleClick}>
          Back Home
        </Button>
      }
    />
  );
};

export default NoPermissionPage;
