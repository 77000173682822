import { createQueryKeys } from '@lukemorales/query-key-factory';

import { FetchAllTargetSequenceParams } from '@/modules/Requirement/types';
import {
  fetchAllTargetSequence,
  fetchTargetSequence,
  getProposalDetail,
  getRequirementById,
} from '@/services/requirements';

export const requirements = createQueryKeys('requirements', {
  targetSequence: (params?: FetchAllTargetSequenceParams) => ({
    queryKey: [params],
    queryFn: () => fetchAllTargetSequence(params),
  }),
  detail: (id: number | string) => ({
    queryKey: [`${id}`],
    queryFn: () => getRequirementById(id),
    contextQueries: {
      targetSequence: {
        queryKey: null,
        queryFn: () => fetchTargetSequence(id),
      },
      proposal: {
        queryKey: null,
        queryFn: () => getProposalDetail(id),
      }
    },
  }),
});
