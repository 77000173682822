import { useQueryClient } from '@umijs/max';
import { Input, Modal, Switch } from 'antd';
import { Flag } from 'lucide-react';
import { ReactElement, useEffect } from 'react';

import { useBoolean } from '@/hooks/useBoolean';
import { useUpdate } from '@/hooks/useUpdate';
import { queries, QueryKeys } from '@/queries';
import { FeatureFlag } from '@/services/features';

type QueryKey = QueryKeys['features']['detail']['queryKey'];

export const FeatureFlagItem = ({ queryKey }: { queryKey: QueryKey }) => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<FeatureFlag>(queryKey) ?? {
    name: queryKey[queryKey.length - 1],
    enabled: false,
    description: '',
  };
  const update = useUpdate();

  const handleChange = () => {
    queryClient.setQueryData(queryKey, { ...data, enabled: !data.enabled });
    update();
  };

  return (
    <li className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Flag className="h-4 w-4" />
        <span className="text-base">
          {data?.name ?? queryKey[queryKey.length - 1]}
        </span>
      </div>
      <Switch checked={data.enabled} onChange={handleChange} />
    </li>
  );
};

export const FeatureFlagDevtools = (): ReactElement | null => {
  const queryClient = useQueryClient();
  const featureFlags = queryClient.getQueryCache().findAll({
    queryKey: queries.features.detail._def,
  });

  const { value: open, toggle } = useBoolean(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        toggle();
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  return (
    <Modal
      open={open}
      title={<Input placeholder="Type to search..." variant="borderless" />}
      footer={null}
      closable={false}
      onCancel={toggle}
    >
      <ul>
        <div className="mb-2">
          <span className="font-semibold text-sm text-secondary/70">
            Feature Flags
          </span>
        </div>
        {featureFlags.map((flag) => (
          <FeatureFlagItem
            key={flag.queryHash}
            queryKey={flag.queryKey as QueryKey}
          />
        ))}
      </ul>
    </Modal>
  );
};
