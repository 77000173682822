export enum AccountType {
  External = 'external',
  Internal = 'internal',
}

export const ACCOUNT_TYPES: Array<{
  title: string;
  value: AccountType;
}> = [
  {
    title: 'External Clients',
    value: AccountType.External,
  },
  {
    title: 'Internal Employees',
    value: AccountType.Internal,
  },
];

export const MFA_REQUIRED_KEY = 'mfa@davinci';

export enum LoginError {
  MFA_REQUIRED = 'MFA_REQUIRED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  INTERNAL_USER = 'INTERNAL_USER', //内部用户
}
