import { REQUIREMENTS_API } from '@/constants/api';
import { AssayOrderReq, AssayUpdateReq } from '@/modules/AssayOrder/assay-form';
import {
  AssignRequirementRequest,
  FetchAllTargetSequenceParams,
  FileBindRequest,
  IAssignPmReq,
  IBindRequirementFileReq,
  IRequirement,
  IRequirementDetail,
  TargetSequence,
} from '@/modules/Requirement/types';
import { APIListRes, APIRes } from '@/services/types';
import request from '@/utils/request';

export const createRequirement = (
  formValues: AssayOrderReq,
): Promise<APIRes> => {
  return request(REQUIREMENTS_API, {
    method: 'POST',
    data: formValues,
  });
};

export const fetchRequirements = (
  type?: 'contracts' | 'proposals',
  params?: any,
): Promise<APIListRes<IRequirement>> => {
  const url = type ? `${REQUIREMENTS_API}/${type}` : REQUIREMENTS_API;
  return request.get(url, {
    params,
  });
};

export const getRequirementById = <T>(
  id: number | string,
): Promise<APIRes<IRequirementDetail<T>>> => {
  return request.get(`${REQUIREMENTS_API}/${id}`);
};

export const updateRequirement = (data: AssayUpdateReq) => {
  return request.put(REQUIREMENTS_API, data);
};

export const confirmRequirement = (data: { requirementId: number }) => {
  return request.put(`${REQUIREMENTS_API}/confirm`, data);
};

export const assignRequirementPm = (
  data: IAssignPmReq,
): Promise<APIRes<null>> => {
  return request.put(`${REQUIREMENTS_API}/assign`, data);
};

export const assignRequirementSR = (data: AssignRequirementRequest) => {
  return request.put(`${REQUIREMENTS_API}/assign/sr`, data);
};

export type RequirementFileSubmitUrls =
  | 'contract/file'
  | 'proposal/file'
  | 'contract/sign'
  | 'co/sign'
  | 'co/file';

export const bindRequirementFile = (
  submitUrl: RequirementFileSubmitUrls,
  data: IBindRequirementFileReq,
): Promise<APIRes> => {
  return request.post(`${REQUIREMENTS_API}/${submitUrl}`, data);
};

export const uploadDeviceFile = (
  data: IBindRequirementFileReq,
): Promise<APIRes> => {
  return request.post(`${REQUIREMENTS_API}/proposal/file`, data);
};

type ConfirmProposalRequest = {
  requirementIdList?: number[];
  requirementId: number;
  leaveMsg?: string;
};

export const sendProposalToBd = (
  data: ConfirmProposalRequest,
): Promise<APIRes> => request.put(`${REQUIREMENTS_API}/proposal/confirm`, data);

export const sendContractToClient = (data: {
  requirementId: number;
  leaveMsg?: string;
}): Promise<APIRes> =>
  request.put(`${REQUIREMENTS_API}/contract/confirm`, data);

export const terminateRequirement = (data: {
  requirementId: number;
  closeReason: string;
}): Promise<APIRes> => request.put(`${REQUIREMENTS_API}/close`, data);

export const updateRequirementProjectCode = (data: {
  requirementId: number;
  projectCode: string;
}): Promise<APIRes> => {
  return request.put(`${REQUIREMENTS_API}/project-code`, data);
};

export const updateRequirementClientDefinedId = (data: {
  requirementId: number;
  clientDefinedId?: string;
}): Promise<APIRes> => {
  return request.put(`${REQUIREMENTS_API}/client-defined-id`, data);
};

export const groupRequirements = (
  action: 'group' | 'ungroup',
  requirementIdList: number[],
): Promise<APIRes> =>
  request.post(`${REQUIREMENTS_API}/${action}`, {
    requirementIdList,
  });

export const getProposalDetail = (id: number | string): Promise<IRequirement> =>
  request.get(`${REQUIREMENTS_API}/proposals/${id}`).then((res) => res.data);

export const fetchAllTargetSequence = (
  params?: FetchAllTargetSequenceParams,
): Promise<TargetSequence[]> =>
  request
    .get(`${REQUIREMENTS_API}/target-sequence`, { params })
    .then((res) => res.data);

export const fetchTargetSequence = async (
  requirementId: string | number,
): Promise<TargetSequence> =>
  request
    .get(`${REQUIREMENTS_API}/target-sequence/${requirementId}`)
    .then((res) => res.data);

export const uploadTargetSequence = (data: FileBindRequest): Promise<APIRes> =>
  request.post(`${REQUIREMENTS_API}/target-sequence/bind`, data);

export const confirmTargetSequence = (requirementId: string) =>
  request.put(`${REQUIREMENTS_API}/target-sequence/confirm/${requirementId}`);
