import { ReactNode } from 'react';

import { Icon } from '@@/exports';

const iconProps = {
  width: '20px',
  height: '20px',
};

export const MENU_ICON_MAPPING: Record<string, ReactNode> = {
  ProjectOverview: <Icon {...iconProps} icon="local:project-overview" />,
  ProjectOverviewObservation: (
    <Icon {...iconProps} icon="local:project-overview-observation" />
  ),
  ServiceCatalog: <Icon {...iconProps} icon="local:service-catalog" />,
  Collaboration: <Icon {...iconProps} icon="local:collaboration" />,
  DataService: <Icon {...iconProps} icon="local:data-service" />,
  DataServiceObservation: (
    <Icon {...iconProps} icon="local:data-service-observation" />
  ),
  Home: <Icon {...iconProps} icon="local:home" />,
  ChangeOrder: <Icon {...iconProps} icon="local:change-order" />,
  Contract: <Icon {...iconProps} icon="local:contract" />,
  Client: <Icon {...iconProps} icon="local:client" />,
  Proposal: <Icon {...iconProps} icon="local:proposal" />,
  Project: <Icon {...iconProps} icon="local:project" />,
  Shipment: <Icon {...iconProps} icon="local:shipments" />,
  InvitationManagement: (
    <Icon {...iconProps} icon="local:invitation-management" />
  ),
  UserManagement: <Icon {...iconProps} icon="local:user-management" />,
  TargetSequence: <Icon {...iconProps} icon="local:target-sequence" />,
  Statistics: <Icon {...iconProps} icon="local:statistics" />,
};
