import { history, useModel } from '@umijs/max';
import { useEffect } from 'react';

import { useFeatureFlag } from '@/components/feature-flag/useFeatureFlag';
import { COMMON_PATHS, INTERNAL_PATHS } from '@/constants/routes';
import { AccountRoleName } from '@/modules/UserSelect/types';
import { clearToken } from '@/utils/cache';
import { getInternalPath } from '@/utils/path';
import { hasRole } from '@/utils/permission';

export const useSiteMaintenance = (isLogin = false) => {
  const siteDown = useFeatureFlag('site_maintenance');
  const { initialState } = useModel('@@initialState');
  const hasAdminRole = hasRole(initialState?.roleList, AccountRoleName.Admin);

  const goToMaintenance = () => {
    clearToken();
    history.push(COMMON_PATHS.SiteMaintenance);
  };

  useEffect(() => {
    // 如果管理员已登录，不做拦截
    if (hasAdminRole) return;
    // 如果 site_maintenance 开启
    if (siteDown) {
      // 非登录界面，直接跳转站点关停页面
      if (!isLogin) {
        goToMaintenance();
      } else {
        // 登录页面，如果带跳转管理员参数，则不拦截
        const isAdminLogin =
          window.location.search ===
          `?backUrl=${encodeURIComponent(
            getInternalPath(INTERNAL_PATHS.SiteAdmin),
          )}`;
        if (!isAdminLogin) {
          goToMaintenance();
        }
      }
    }
  }, [siteDown]);
};
