import { BATCH_API } from '@/constants/api';
import {
  IBatchActivity,
  IBatchDetail,
  WorkBatchMoleculeListResponse,
  WorkBatchMoleculeSummaryResponse,
} from '@/modules/Batch/types';
import { APIRes } from '@/services/types';
import request from '@/utils/request';

import { FileRes } from './file.type';

export const getBatchActivities = (
  batchId: number,
): Promise<APIRes<IBatchActivity[]>> =>
  request.get(`${BATCH_API}/activities`, {
    params: {
      batchId,
    },
  });

export const getBatchById = (id: number): Promise<APIRes<IBatchDetail>> =>
  request.get(`${BATCH_API}/detail/${id}`);

export interface IWorkBatchDetail {
  id: number; // work batch detail id
  comment?: string;
  requirementType?: string;
  analysisType?: string;
  businessType?: string;
  hostCell?: string;
  scopePurificationStep?: string;
  mlAccountId: number;
  flAccountId: number;
  alAccountId: number;
  site: string;
  deliverable: string;
  batchScope: string;
  uniqueBatchName: string;
}

// 不是一个work batch，是从requirement创建batch的中间产物
export interface IBatchBasicInfo {
  id: number; // 不是 requirementId, 也不是 batchId，也不是 workBatchId
  requirementIdList: number[];
  assignSrDate?: string;
  wxbCode?: string;
  projectCode: string;
  pilotNeeded?: string;
  contractName?: string;
  contractNamePartOne?: string;
  contractNamePartTwo?: string;
  contractNamePartThree?: string;
  totalRevenue?: string;
  contractFile?: FileRes;
  memo?: string;
  submitDate?: string;
  stage?: string;
  confirmSequenceDate?: string;
  workBatchDetails?: IWorkBatchDetail[]; // todo;
  doubleSign?: boolean;
}

export const getWorkBatches = (
  requirementId: number,
): Promise<APIRes<IBatchBasicInfo>> => {
  return request.get(`/api/v1/requirements/batches/${requirementId}`);
};

export const updateWorkBatches = (data: any): Promise<APIRes> => {
  return request.put(`/api/v1/batches`, data);
};

export const comfimeWorkBatches = (data: any): Promise<APIRes> => {
  return request.put(`/api/v1/batches/confirm`, data);
};

export async function getWorkBatchesOptions(
  data?: any,
): Promise<APIRes<any[]>> {
  return request('/api/v1/sys/dataDictionaries/group/list', {
    method: 'POST',
    data,
  });
}

export async function getWorkBatchsAccountsOptions(
  params?: any,
): Promise<APIRes> {
  return request('/api/v1/accounts/batch-manager/list', {
    method: 'GET',
    params,
  });
}

export const getBatchMoleculeList = (
  batchId: number,
): Promise<WorkBatchMoleculeListResponse[]> => {
  return request
    .get(`${BATCH_API}/molecules/list`, {
      params: {
        batchId,
      },
    })
    .then((res) => res.data);
};

export const getBatchMoleculeOverview = (
  batchId: number,
): Promise<WorkBatchMoleculeSummaryResponse> => {
  return request
    .get(`${BATCH_API}/molecules/overview`, {
      params: {
        batchId,
      },
    })
    .then((res) => res.data);
};
