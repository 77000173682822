import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useProfile } from '@/hooks/useProfile';

const getCurrentTime = () => dayjs().format('YYYY-MM-DD HH:mm ([GMT]Z)');

const AvatarDropdown: React.FC = () => {
  const initialState = useProfile();

  const userBanner =
    initialState && initialState.firstName
      ? `, ${initialState.firstName} from ${initialState.company?.name || ''}`
      : '';
  const [localTime, setLocalTime] = useState(getCurrentTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setLocalTime(getCurrentTime());
    }, 1000 * 30);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <div className="userInfo">
        <p className="userBanner">Welcome back{userBanner}</p>
        {/* <p className="localTime">Local Time: {localTime}</p> */}
        {initialState?.activity?.loginSuccessTime && (
          <p className="localTime">
            Last time we saw you:{' '}
            {dayjs(initialState.activity.loginSuccessTime).format(
              'YYYY-MM-DD HH:mm:ss',
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default AvatarDropdown;
