import { UserInfo } from '@/modules/Account/types';
import { APIRes } from '@/services/types';
import request from '@/utils/request';
export const loginUrl = '/api/v1/accounts/signin';

export const AADLoginUrl = '/api/v1/oauth/aad/callback';

/**登录验证码 */
export async function LoginCaptchas(data?: any): Promise<any> {
  return request('/api/v1/captchas', {
    method: 'POST',
    data,
  });
}

/**登录 */
export async function handleLogin(data: any): Promise<APIRes<any>> {
  return request.post(loginUrl, data, { customErrorMessage: true });
}

/**注册 */
export async function handleRegister(data: any): Promise<any> {
  return request('/api/v1/accounts/signup', {
    method: 'POST',
    data,
  });
}

export async function handleInternalRegister(data: object): Promise<APIRes> {
  return request('/api/v1/accounts/internal/signup', {
    method: 'POST',
    data,
  });
}

/**注册的 下拉地区 */
export async function getZones(params?: any): Promise<any> {
  return request('/api/v1/zones', {
    method: 'GET',
    params,
  });
}

/**注册的 下拉公司 */
export async function getCompanies(params?: any): Promise<any> {
  return request('/api/v1/companies', {
    method: 'GET',
    params,
  });
}

/**获取注册邀请信息 */
export async function getInviterInfo(params?: any): Promise<APIRes<any>> {
  return request(`/api/v1/clients/logs/${params.uuid}`, {
    method: 'GET',
    params,
  });
}

/**AAD 获取access token */
export async function getAADToken(data: any): Promise<APIRes<any>> {
  return request(AADLoginUrl, {
    method: 'POST',
    data,
  });
}

/**获取当前用户请求 */
export async function getUserInfo(params?: any): Promise<APIRes<UserInfo>> {
  return request('/api/v1/accounts', {
    method: 'GET',
    params,
  });
}

/**resetpassword 验证码 */
export async function ApiResetCaptchas(data?: any): Promise<any> {
  return request('/api/v1/captchas/resetPassword', {
    method: 'POST',
    data,
    customErrorMessage: true,
  });
}
/**reset password */
export async function ApiResetPassword(data: any): Promise<APIRes<any>> {
  return request('/api/v1/accounts/resetPassword', {
    method: 'POST',
    data,
    customErrorMessage: true,
  });
}
