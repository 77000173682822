export const CLIENT_PREFIX = 'portal';
export const INTERNAL_PREFIX = 'xnkvihcmtv';
export const BACK_URL_PARAM = 'backUrl';

export const PORTAL_PATHS = {
  ProjectOverview: '/ProjectOverview',
  Project360: '/Project360/:batchId',
  ServiceCatalog: '/ServiceCatalog',
  ProteinProduction: '/ServiceCatalog/ProteinProduction',
  ManageExportInfo: '/ServiceCatalog/ManageExportInfo',
  Assay: '/ServiceCatalog/AssayOrder',
  Collaboration: '/Collaboration',
  DataService: '/DataService',
  TargetSequence: '/target-sequence/:id',
  // Observation user
  ProjectOverviewObservation: '/ProjectOverviewObservation',
  DataServiceObservation: '/DataServiceObservation',
} as const;

export const INTERNAL_PATHS = {
  Home: '/home',
  MyClients: '/MyClients',
  MyProposals: '/MyProposals',
  ProposalPreview: '/MyProposals/:id',
  MyContracts: '/MyContracts',
  NewRequirement: '/NewRequirement', // BD create requirement for client
  MyProjects: '/MyProjects',
  ProjectMemo: '/MyProjects/:id/memo',
  AccessMgmt: '/AccessManagement',
  UserMgmt: '/UserManagement',
  MyCos: '/MyCos',
  MyCosProposal: '/MyCos/:id/proposal',
  SiteAdmin: '/SiteAdmin',
  Shipments: '/Shipments/:id', //PM 的
  MyShipments: '/MyShipments', //SM 的
  ManageShipments: '/ManageMyShipments/:id', //SM 的
  ManageDocuments: '/projects/:batchId/documents/:commonFileId/force-upload',
  ConfirmProjectProgress: '/projects/confirm-progress',
} as const;

export const COMMON_PATHS = {
  MyAccount: '/account/settings',
  Statistics: '/statistics/LabStatistics',
  CostRadar: '/statistics/CostRadar',
  Login: '/login',
  ResetPassword: '/password-reset',
  Register: '/register',
  Message: '/messages/:id',
  MessageList: '/messages',
  AadLoginCallback: '/oauth/aad/callback',
  AadLoginUrl: '/api/v1/oauth/aad',
  Redirecting: '/redirect',
  NotFound: '/not-found',
  SiteMaintenance: '/site-maintenance',
} as const;
