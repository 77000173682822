import {
  inferQueryKeyStore,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';

import { account } from './account';
import { batches } from './batches';
import { blob } from './blob';
import { features } from './features';
import { options } from './options';
import { projectProgress } from './project-progress';
import { requirements } from './requirements';

export const queries = mergeQueryKeys(
  requirements,
  blob,
  options,
  batches,
  account,
  features,
  projectProgress,
);

export type QueryKeys = inferQueryKeyStore<typeof queries>;
