import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getProjectProgressDetail,
  getProjectProgressList,
} from '@/services/project-pregress';

export const projectProgress = createQueryKeys('projectProgress', {
  detail: (batchDetailId: number) => ({
    queryKey: [batchDetailId],
    queryFn: () => getProjectProgressDetail(batchDetailId),
  }),
  list: {
    queryKey: null,
    queryFn: () => getProjectProgressList(),
  },
});
