import {
  KeyOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { history, useAccess } from '@umijs/max';
import { Avatar, Dropdown } from 'antd';
import React from 'react';

import { COMMON_PATHS, PORTAL_PATHS } from '@/constants/routes';
import { FrontPermissionEnum } from '@/enums/permissionEnum';
import { getLocale } from '@/hooks/useLocale';
import { useProfile } from '@/hooks/useProfile';
import { handleLogOut } from '@/modules/Account/auth';
import { getClientPath } from '@/utils/path';

const getShortName = (model: { firstName?: string; lastName?: string }) => {
  const { firstName, lastName } = model;
  return (
    (firstName ? firstName.charAt(0) : '') +
    (lastName ? lastName.charAt(0) : '')
  );
};

/**个人信息 */
const AvatarDropdown: React.FC = () => {
  const access = useAccess();
  const initialState = useProfile();

  const logOut = () => {
    handleLogOut();
  };

  const handleJumpManageExportInfo = () => {
    history.push(getClientPath(PORTAL_PATHS.ManageExportInfo));
  };

  const gotoMyAccount = () => {
    history.push(getClientPath(COMMON_PATHS.MyAccount));
  };

  return (
    <div className="avatar">
      <Dropdown
        trigger={['click']}
        menu={{
          items: [
            {
              label: (
                <span className="flex gap-2 items-center">
                  <KeyOutlined />
                  My Account
                </span>
              ),
              key: 'my-account',
              onClick: gotoMyAccount,
            },
            ...(access[FrontPermissionEnum.serviceCatalog]
              ? [
                  {
                    label: (
                      <span className="flex gap-2 items-center">
                        <SettingOutlined />
                        Manage Export Info
                      </span>
                    ),
                    key: 'ManageExportInfo',
                    onClick: handleJumpManageExportInfo,
                  },
                ]
              : []),
            {
              label: (
                <span className="flex gap-2 items-center">
                  <LogoutOutlined />
                  {getLocale('common.logout')}
                </span>
              ),
              key: 'logOut',
              onClick: logOut,
            },
          ],
        }}
      >
        <div className="headItem">
          <Avatar style={{ backgroundColor: 'var(--color-primary)' }}>
            {getShortName(initialState ?? {})}
          </Avatar>
        </div>
      </Dropdown>
    </div>
  );
};

export default AvatarDropdown;
