export const DEFAULT_LAYOUT_SETTINGS: any = {
  name: 'User',
  avatar: 'https://img.alicdn.com/tfs/TB1YHEpwUT1gK0jSZFhXXaAtVXa-28-27.svg',
  settings: {
    layout: 'side',
    contentWidth: 'Fluid',
    navTheme: 'light', //light realDark
    colorPrimary: '#337dff',
    collapsed: true,
  },
};
