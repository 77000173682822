import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { isString } from 'lodash-es';

import { DEFAULT_ERROR_MSG } from '@/constants/error';
import { COMMON_PATHS } from '@/constants/routes';
import { RequestEnum } from '@/enums/httpEnum';
import { getLocale } from '@/hooks/useLocale';
import { handleLogOut } from '@/modules/Account/auth';

import { clearToken, getToken, setLocalCaches, setToken } from './cache';
import { handleRefreshToken, isRefreshRequest } from './refreshToken';
import request from './request';

export const requestAuthInterceptor = async (config: any) => {
  const params = config.params || {};
  const data = config.data || false;

  if (config.method?.toUpperCase() === RequestEnum.GET) {
    if (!isString(params)) {
      // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
      const now = new Date().getTime();
      config.params = Object.assign(params || {}, { _t: now });
    }
  } else {
    if (
      Reflect.has(config, 'data') &&
      config.data &&
      Object.keys(config.data).length > 0
    ) {
      config.data = data;
      config.params = params;
    } else {
      // 非GET请求如果没有提供data，则将params视为data
      config.data = params;
      config.params = undefined;
    }
  }

  const token = getToken();
  if (token) {
    config.headers.Authorization = token;
  }

  return config;
};

export const responseSaveTokenInterceptor = async (response: AxiosResponse) => {
  if (response.headers.authorization) {
    // 保存token
    const token = response.headers.authorization;
    if (token) setToken(token);
  }

  if (response.headers.refresh_token) {
    // 保存 refresh_token
    const refreshToken = response.headers.refresh_token;
    if (refreshToken) setLocalCaches('refreshToken', refreshToken);
  }
  return Promise.resolve(response.data);
};

export const responseErrorHandlerInterceptor = async (error: any) => {
  const { response, message: msg } = error || {};
  try {
    // 后端有 > 400 的错误响应
    if (response) {
      const { status, data, config } = response;
      // 刷新token：token失效并且不是refreshToken接口
      if (status === 401 && !isRefreshRequest(response.config)) {
        const isSuccess = await handleRefreshToken();
        if (isSuccess) {
          response.config.headers.Authorization = getToken();
          const resp = await request.request(response.config);
          return resp;
        } else {
          // 无权限 退出
          handleLogOut();
          return Promise.reject(error.response);
        }
      }
      if (status === 418) {
        // 站点关停特殊状态, toggle (site_maintenance) enabled=true, DV-1160
        clearToken();
        message.error('Our site is under maintenance!');
        window.location.href = COMMON_PATHS.SiteMaintenance;
        return Promise.reject({});
      }
      if (status >= 400 && status < 500) {
        if (data.message && !error?.config?.customErrorMessage) {
          message.error(data.message);
        }
        return Promise.reject({
          ...data,
          status,
        });
      }
      if (status === 500) {
        message.error(data.message ?? DEFAULT_ERROR_MSG);
        if (!!config.isRefreshToken) {
          // 到登录页
          handleLogOut(true);
        }
        return Promise.reject(response.data);
      }
      message.error(DEFAULT_ERROR_MSG);
      return Promise.reject(response.data);
    }

    // 后端无响应
    const err: string = error?.toString?.() ?? '';
    // 请求超时
    if (msg?.indexOf('timeout') !== -1) {
      message.error(getLocale('common.apiTimeout'));
      return Promise.reject(error);
    }
    // 网络错误
    if (err?.includes('Network Error')) {
      message.error(getLocale('common.networkError'));
      return Promise.reject({
        message: 'Network error',
      });
    }
    return Promise.reject({
      data: null,
      message: 'Unknown request error',
    });
  } catch (error) {
    throw new Error(error as string);
  }
};
