import { CacheEnum } from '@/enums/httpEnum';

import { AesEncryption } from './cipher';

// 开启缓存加密后，加密密钥。采用aes加密
export const cacheCipher = {
  key: '_11111000001111@',
  iv: '@11111000001111_',
};

const encryption = new AesEncryption({
  key: cacheCipher.key,
  iv: cacheCipher.iv,
});

const isDev = process.env.UMI_ENV === 'dev';

export const getTokenCache = () => {
  const stringifyValue = localStorage.getItem(CacheEnum.DAVINCI);
  if (!stringifyValue) return {};

  const stringData = isDev
    ? stringifyValue
    : encryption.decryptByAES(stringifyValue);
  return JSON.parse(stringData);
};

export const getToken = () => {
  const obj = getTokenCache();
  return obj[CacheEnum.TOKEN];
};

export const setToken = (value: any) => {
  let obj = getTokenCache();

  let stringData = JSON.stringify({
    ...obj,
    [CacheEnum.TOKEN]: value,
  });

  const stringifyValue = isDev
    ? stringData
    : encryption.encryptByAES(stringData);

  return localStorage.setItem(CacheEnum.DAVINCI, stringifyValue);
};

export const clearToken = () => {
  localStorage.removeItem(CacheEnum.DAVINCI);
};

const getKey = (key: string) => {
  return `${key}`.toUpperCase();
};

export const getProjectCache = () => {
  let stringifyValue = localStorage.getItem(CacheEnum.DAVINCI);
  if (!stringifyValue) return {};

  let stringData = isDev
    ? stringifyValue
    : encryption.decryptByAES(stringifyValue);
  return JSON.parse(stringData);
};

export const getLocalCaches = (key: any) => {
  let obj = getProjectCache();
  return obj[getKey(key)];
};

export const setLocalCaches = (key: any, value: any) => {
  let obj = getProjectCache();
  let stringData = JSON.stringify({
    ...obj,
    [getKey(key)]: value,
  });

  const stringifyValue = isDev
    ? stringData
    : encryption.encryptByAES(stringData);

  return localStorage.setItem(CacheEnum.DAVINCI, stringifyValue);
};
