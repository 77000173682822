import { ACCOUNT_API } from '@/constants/api';
import { AccountSecurityConfig } from '@/modules/Account/types';
import { IAccount, IAccountRole } from '@/modules/UserSelect/types';
import { APIListRes, APIRes } from '@/services/types';
import request from '@/utils/request';

const ROLE_ENDPOINT = '/api/v1/roles';

export const getUsersByRole = (
  roleName: string,
): Promise<APIListRes<IAccount>> =>
  request(`${ACCOUNT_API}/list`, {
    method: 'GET',
    params: {
      roleName,
    },
  });

export const getRoles = (params: {
  group?: string;
}): Promise<APIListRes<IAccountRole>> => request.get(ROLE_ENDPOINT, { params });

export const updateMfa = (): Promise<APIRes<boolean>> => {
  return request.put(`${ACCOUNT_API}/settings/mfa`);
};

export const getAccountSecurityConfig = (): Promise<AccountSecurityConfig> =>
  request.get(`${ACCOUNT_API}/settings/security`).then((res) => res.data);

export const updateAccountSecurityConfig = (
  data: Partial<AccountSecurityConfig>,
): Promise<AccountSecurityConfig> =>
  request.put(`${ACCOUNT_API}/settings/security`, data).then((res) => res.data);
